import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'components/Layout'
import LocalImage from 'components/LocalImage'

import imageHeader from 'static/images/publish/header/header_picture.webp'
import imageHeaderPost from 'static/images/publish/header/post.webp'
import imageHeaderPostSmall from 'static/images/publish/header/post_small.webp'
import imageHeaderSchedule from 'static/images/publish/header/schedule.webp'
import imageHeaderTikTokTime from 'static/images/publish/header/tiktok_time.webp'
import imageHeaderTime from 'static/images/publish/header/time.webp'
import imageHeaderWednesday from 'static/images/publish/header/wednesday.webp'

const StyledImageHeader = styled(LocalImage)`
  position: absolute;
`

const StyledImageHeaderPost = styled(StyledImageHeader)`
  filter: drop-shadow(0px 13.8857px 15.4286px rgba(14, 28, 56, 0.07));
  ${({ coefficient }) => `  
  top: ${coefficient * 140}px;
  right: ${coefficient * 203}px;
  width: ${coefficient * 156}px;
  height: ${coefficient * 217.8}px`};
  animation: publish-image-header-post 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes publish-image-header-post {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: scale(1.06) translateY(-8px) translateX(-16px);
    }
    33.3333333333% {
      transform: scale(1.06) translateY(-8px) translateX(-16px);
    }
    50.0000000001% {
      transform: scale(1.12) translateY(-16px) translateX(-16px);
    }
    66.6666666668% {
      transform: scale(1.12) translateY(-16px) translateX(-16px);
    }
    83.3333333335% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

const StyledImageHeaderPostSmall = styled(StyledImageHeader)`
  box-shadow: -4.27885px 6.49529px 15.7718px rgba(14, 28, 56, 0.04),
    -2.56731px 14.1935px 26.0411px rgba(14, 28, 56, 0.07);

  ${({ coefficient }) => `  
  right: ${coefficient * 70}px;
  bottom: ${coefficient * 90}px;
  width: ${coefficient * 83.87}px;
  height: ${coefficient * 89.02}px`};
  animation: publish-image-header-instagram-post-small 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes publish-image-header-instagram-post-small {
    0% {
      transform: rotateX(0);
    }
    50% {
      transform: rotateX(-180deg);
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
  }
`

const StyledImageHeaderSchedule = styled(StyledImageHeader)`
  box-shadow: 0px 2px 13px rgba(43, 74, 134, 0.05), 20px 20px 60px rgba(21, 46, 77, 0.08);

  ${({ coefficient }) => `  
  left: ${coefficient * 127}px;
  bottom: ${coefficient * -40}px;
  width: ${coefficient * 124.42}px;
  height: ${coefficient * 144.95}px`};

  animation: publish-image-header-message 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes publish-image-header-message {
    0% {
      transform: translateY(0) translateX(0);
    }
    16.6666666667% {
      transform: translateY(8px);
    }
    33.3333333333% {
      transform: translateY(8px);
    }
    50.0000000001% {
      transform: translateY(-8px);
    }
    66.6666666668% {
      transform: translateY(-8px);
    }
    83.3333333335% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

const StyledImageHeaderTikTokTime = styled(StyledImageHeader)`
  box-shadow: -5px 7.59px 18.43px rgba(14, 28, 56, 0.04), -3px 16.5857px 30.43px rgba(14, 28, 56, 0.07);
  ${({ coefficient }) => `  
  right: ${coefficient * 50}px;
  bottom: ${coefficient * 205}px;
  width: ${coefficient * 186.33}px;
  height: ${coefficient * 43}px`};

  animation: publish-image-header-tiktok 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes publish-image-header-tiktok {
    0%,
    7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-15deg);
    }
    20% {
      transform: rotateZ(10deg);
    }
    25% {
      transform: rotateZ(-10deg);
    }
    30% {
      transform: rotateZ(6deg);
    }
    35% {
      transform: rotateZ(-4deg);
    }
    40%,
    100% {
      transform: rotateZ(0);
    }
  }
`

const StyledImageHeaderTime = styled(StyledImageHeader)`
  box-shadow: -4.07871px 6.19149px 15.0341px rgba(10, 35, 84, 0.06), -2.44723px 13.5297px 24.823px rgba(0, 32, 95, 0.09);

  ${({ coefficient }) => `  
  left: ${coefficient * 150}px;  
  top: ${coefficient * 22}px;
  width: ${coefficient * 152}px;
  height: ${coefficient * 35.08}px`};
  animation: publish-image-header-time 8s linear 1s infinite both;
  @keyframes publish-image-header-time {
    0% {
      transform: translateX(0);
    }
    16.6666666667% {
      transform: translateX(8px);
    }
    33.3333333333% {
      transform: translateX(16px);
    }
    50.0000000001% {
      transform: translateX(24px);
    }
    66.6666666668% {
      transform: translateX(32px);
    }
    83.3333333335% {
      transform: translateX(40px);
    }
    100% {
      transform: translateX(0);
    }
  }
`

const StyledImageHeaderWednesday = styled(StyledImageHeader)`
  box-shadow: 0px 14px 23.61px rgba(26, 64, 115, 0.19);
  ${({ coefficient }) => `  
  right: ${coefficient * 88}px; 
  bottom: ${coefficient * 252}px;
  width: ${coefficient * 86}px;
  height: ${coefficient * 22}px`};
`

const HeaderImageAnimation = () => {
  // this is a coefficient of the image. Real size 500px, but on the site it is 400px, so the coefficient would be 400/500
  const [coefficient, setCoefficient] = useState(1)
  const imageWrapperRef = useRef(null)

  const countCoefficient = () => {
    if (imageWrapperRef && imageWrapperRef.current) {
      const { current: { clientWidth, firstChild: { naturalWidth } = {} } = {} } = imageWrapperRef
      const newCoefficient = (clientWidth * 2) / naturalWidth
      if (!Number.isNaN(newCoefficient) && newCoefficient > 0) {
        setCoefficient(newCoefficient)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', countCoefficient)

    return () => {
      window.removeEventListener('resize', countCoefficient)
    }
  }, [])

  return (
    <Flex width="100%">
      <Flex maxWidth="688px" mx="auto">
        <Box width="100%" position="relative" ref={imageWrapperRef}>
          <LocalImage
            src={imageHeader}
            width="100%"
            maxWidth="688px"
            mx="auto"
            alt="Everything You Need to Publish on Social Media"
            onLoad={() => {
              countCoefficient()
            }}
          />

          <StyledImageHeaderPost src={imageHeaderPost} coefficient={coefficient} />
          <StyledImageHeaderPostSmall src={imageHeaderPostSmall} coefficient={coefficient} />
          <StyledImageHeaderSchedule src={imageHeaderSchedule} coefficient={coefficient} />
          <StyledImageHeaderTikTokTime src={imageHeaderTikTokTime} coefficient={coefficient} />
          <StyledImageHeaderTime src={imageHeaderTime} coefficient={coefficient} />
          <StyledImageHeaderWednesday src={imageHeaderWednesday} coefficient={coefficient} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default HeaderImageAnimation
